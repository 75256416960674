// Imports
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
//  End Imports

//Apline
window.Alpine = Alpine
Alpine.plugin(collapse);
Alpine.start()

// Fancybox
window.Fancybox = Fancybox;
Fancybox.bind("[data-fancybox]");

// Scroll animations
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import scrollingAnimations from './scrolling-animations';
window.scrollingAnimations = scrollingAnimations;
scrollingAnimations.create({
    once: true
});

const fullWidthHero = document.getElementById('hero-fullWidth');

if (fullWidthHero) {
    const image = fullWidthHero.querySelector('.scrolling-element');
    gsap.set(image, { y: '-15%' });
    gsap.to(image, {
        y: '0',
        ease: "none",
        scrollTrigger: {
            scrub: 1,
            start: "clamp(top center)",
            trigger: fullWidthHero,
            // markers: true,
        },
    });
}